import React, { Component } from 'react'
import Home from './components/Home.js'
import Papa from 'papaparse'
import _ from 'underscore'
import ReactGA from 'react-ga'
import { ReactIsInDevelomentMode as DevMode } from './Constants'

async function GetData(callback) {
  Papa.parse(await fetchCsv(), {
    complete: (lol) => {
      var hang = convertToArrayOfObjects(lol.data)
      callback(hang)
    },
  })
}

function convertToArrayOfObjects(data) {
  var keys = data.shift(),
    i = 0,
    k = 0,
    obj = null,
    output = []

  for (i = 0; i < data.length; i++) {
    obj = {}

    for (k = 0; k < keys.length; k++) {
      obj[keys[k]] = data[i][k]
    }

    output.push(obj)
  }

  return output
}

async function fetchCsv() {
  const response = await fetch('data/games-data.csv')
  const reader = response.body.getReader()
  const result = await reader.read()
  const decoder = new TextDecoder('utf-8')
  const csv = await decoder.decode(result.value)
  return csv
}

export class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      projects: [],
    }

    GetData((data) => this.dataLoaded(data))
  }

  componentDidMount() {
    if (!DevMode()) {
      const trackingId = 'UA-177909978-1'
      ReactGA.initialize(trackingId)
      ReactGA.pageview('/home')
    }
  }

  dataLoaded = (data) => {
    data.forEach((obj) => {
      obj.priority = +obj.priority // use `+` to convert your string to a number
      obj.priority *= -1
    })

    var sortedData = _.sortBy(data, 'priority')
    this.setState({ projects: sortedData })
  }
  render() {
    return (
      <div>
        <Home projectsData={this.state.projects} />
      </div>
    )
  }
}

export default App
