import React, { Component } from 'react'

export class SocialMediaContainer extends Component {
  render() {
    var iconStyle = {
      width: '25px',
      height: '25px',
      marginRight: '10px',
    }
    return (
      <div>
        <a
          style={iconStyle}
          href="https://github.com/ahmkam"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
        <a
          style={iconStyle}
          href="https://linkedin.com/in/ahmedkl"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <a
          style={iconStyle}
          href="https://twitter.com/ahmedkl"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        <a
          style={iconStyle}
          href="mailto:ahmkam06@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Email
        </a>
      </div>
    )
  }
}

export default SocialMediaContainer
