import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Title extends Component {
  render() {
    return (
      <div>
        <Link to="/">
          <h1>Ahmed Kamal</h1>
        </Link>
      </div>
    )
  }
}

export default Title
