import React, { Component } from 'react'
import Title from './headerItems/Title'
import SocialMediaContainer from './headerItems/SocialMediaContainer'
import { Image, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export class Header extends Component {
  render() {
    return (
      <div
        style={{
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        <Row>
          <Col>
            <Link to="/">
              <Image
                src="/images/picture.jpg"
                roundedCircle
                style={{
                  maxWidth: '256px',
                  maxHeight: '256px',
                }}
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Title />
            <SocialMediaContainer />
            <p style={{ marginTop: '10px' }}>
              Game & Interactive developer. Currently working at{' '}
              <a
                href="https://www.rovio.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#ef2528' }}
              >
                Rovio
              </a>
              <br />
              Experience working on 2D/3D games, AR/VR, interactive applications
              & a bit of web
            </p>
          </Col>
        </Row>
        <hr />
      </div>
    )
  }
}

export default Header
