import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <div style={{ marginBottom: '20px' }}>
        <hr />
        <p>Made with React & Bootstrap</p>
      </div>
    )
  }
}

export default Footer
