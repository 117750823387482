import React, { Component } from 'react'
import ProjectCard from './projects/ProjectCard'

import { Row, Col } from 'react-bootstrap'

export class Body extends Component {
  constructor(props) {
    super(props)
    this.state = {
      renderElements: true,
      selectedProject: null,
    }
  }

  OnClick = (id) => {
    var data = this.props.projectsData.find((o) => o.id === id)
    this.setState({ selectedProject: data })
    this.setState({ renderElements: false })
  }

  render() {
    var arr = this.props.projectsData
    var elements = []
    var itemsPerRow = 3
    var totalItems = arr.length
    for (var i = 0; i < arr.length; i++) {
      elements.push(
        <Col
          key={arr[i].id}
          style={{
            marginBottom: i + itemsPerRow < totalItems ? '25px' : '10px',
          }}
          md={4}
          sm={6}
          xs={12}
        >
          <ProjectCard value={arr[i]} OnCardClicked={this.OnClick} />
        </Col>
      )
    }

    return (
      <div style={{ marginTop: '10px' }}>
        <Row>{elements}</Row>
      </div>
    )
  }
}

export default Body
