import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { Image, Row, Col } from 'react-bootstrap'
import ReactGA from 'react-ga'
import { ReactIsInDevelomentMode as DevMode } from '../../Constants'

export class ProjectPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.data) {
      if (!DevMode()) {
        ReactGA.pageview(`/${this.props.data.id}`)
      }
    }
  }

  render() {
    var gameData = this.props.data

    if (gameData) {
      var hasVideo = gameData.video
      var videoElement
      if (hasVideo) {
        videoElement = (
          <Col
            md={6}
            style={{
              marginBottom: '10px',
            }}
          >
            <ReactPlayer style={{ maxWidth: '100%' }} url={gameData.video} />
          </Col>
        )
      }

      var totalImages = gameData.totalImages
      var id = gameData.id
      var images = []

      for (var i = 0; i < totalImages; i++) {
        var floatCheck = gameData.video
          ? i % 2 === 0
            ? 'left'
            : 'right'
          : i % 2 === 0
          ? 'right'
          : 'left'

        images.push(
          <Col
            md={6}
            style={{
              marginBottom: '20px',
            }}
          >
            <Image
              style={{
                maxHeight: '100vh',
                float: floatCheck,
              }}
              src={`images/projects/${id}/${i + 1}.png`}
              fluid
            />
          </Col>
        )
      }

      var googlePlay
      if (gameData.googleplay) {
        var url = gameData.googleplay
        googlePlay = (
          <a href={url} target="_blank" rel="noopener noreferrer">
            View On Google Play!
          </a>
        )
      }

      var web
      if (gameData.web) {
        var urlWeb = gameData.web
        web = (
          <a href={urlWeb} target="_blank" rel="noopener noreferrer">
            Try now on Web!
          </a>
        )
      }

      var devlog
      if (gameData.devlog) {
        var urlDevlog = gameData.devlog
        devlog = (
          <a href={urlDevlog} target="_blank" rel="noopener noreferrer">
            Checkout the Devlog here!
          </a>
        )
      }

      var features = gameData.features.split(',')
      var featureList = []
      for (var j = 0; j < features.length; j++) {
        featureList.push(
          <div>
            <p>
              <li>{features[j]}</li>
            </p>
          </div>
        )
      }

      var boldStyle = { fontWeight: 'bold' }

      return (
        <div>
          <Row>
            <Col md={6}>
              <h2>{gameData.title}</h2>
              <p>{gameData.description}</p>
              <b style={boldStyle}>Features</b>
              <br />
              {featureList}
              <br />
              <b style={boldStyle}>Tech</b>
              <p>{gameData.tech}</p>
              <b style={boldStyle}>Role</b>
              <p>{gameData.role}</p>
              {googlePlay}
              {web}
              {devlog}
            </Col>
            {videoElement}
            {images}
          </Row>
        </div>
      )
    }

    return <div></div>
  }
}

export default ProjectPage
