import React, { Component } from 'react'
import { Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export class ProjectCard extends Component {
  render() {
    return (
      <div>
        <Link to={`/${this.props.value.id}`} onClick={this.props.OnCardClicked}>
          <img
            width="100%"
            height="100%"
            alt="nope"
            src={`/images/projects/${this.props.value.id}/thumbnail.png`}
          />
          <h3
            style={{ fontSize: '18px', marginTop: '10px', marginBottom: '5px' }}
          >
            {this.props.value.title}
          </h3>
        </Link>
        <p style={{ fontSize: '14px', marginBottom: '0px' }}>
          {this.props.value.tagline}
        </p>
        <Badge style={{ marginBottom: '10px' }} variant="light">
          {this.props.value.category}
        </Badge>
      </div>
    )
  }
}

export default ProjectCard
