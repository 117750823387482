import React, { Component } from 'react'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'
import { Container, Row, Col } from 'react-bootstrap'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ProjectPage from './projects/ProjectPage'

export class Home extends Component {
  render() {
    return (
      <div>
        <Container style={{ width: '90%' }}>
          <Router>
            <Row>
              <Col>
                <Header />
              </Col>
            </Row>
            <Row>
              <Col>
                <Route
                  path="/"
                  exact
                  component={() => (
                    <Body
                      projectsData={this.props.projectsData}
                      OnCardClicked={this.OnClick}
                    />
                  )}
                />
                <Route
                  path="/:id"
                  exact
                  OnEnter={() => {
                    console.log('on enter')
                  }}
                  component={(propData) => (
                    <ProjectPage
                      {...propData}
                      data={this.props.projectsData.find(
                        (o) => o.id === propData.match.params.id
                      )}
                    />
                  )}
                />
              </Col>
            </Row>
          </Router>
          <Row>
            <Col>
              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Home
